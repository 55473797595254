import request from '@/utils/http'

export function createNotice(data) {
    return request({
        url: `/announcement/create`,
        method: 'post',
        data
    })
}

export function noticeDetail(data) {
    return request({
        url: `/announcement/detail/${data}`,
        method: 'get'
    })
}

export function editNotice(data) {
    return request({
        url: `announcement/update`,
        method: 'put',
        data
    })
}
